<template>
  <div class="all-height pl-3">
    <!-- <div class="text-center display-1 my-4">
      <img
        src="/img/consark/logo.png"
        width="250"
        class="consark-logo-image"
        alt="consark-logo-image"
      />
    </div> -->
    <div>
      <div class="mb-6 d-flex align-center">
        <div class="title">
          <v-icon>mdi-chevron-triple-right</v-icon>
          <span class="ml-4">Status & Pending Tasks</span>
        </div>
        <v-spacer></v-spacer>
        <v-btn class="shadow-off" text @click="refreshData()"><v-icon left>mdi-refresh</v-icon>Refresh</v-btn>
      </div>
      <div v-if="projects.length > 0" class="mb-10">
        <v-row>
          <template v-for="(v, k) in projects">
            <v-col cols="12" md="4" :key="k" v-if="v.__dname === 'pbc'">
              <v-card class="all-width all-height " style="border: 1px solid #ddd;">
                <v-card-text class="py-3 d-flex align-center">
                  <div class="title primary--text font-weight-bold">
                    {{
                      (
                        $store.state.subscriptions[v.__dname].product_detail ||
                        {}
                      ).name
                    }}
                  </div>
                  <v-spacer></v-spacer>
                  <v-btn outlined small class="shadow-off" color="primary" @click="
                    $nova.gotoLink({ path: '/pbc/project/view/' + v._id })
                    ">View</v-btn>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text class="pt-1 pb-2">
                  <div class="title">{{ v.name }}</div>
                  <div class="d-flex align-center grey--text text--darken-2">
                    <span class="pr-2 font-weight-bold">Due:</span>
                    <span>{{ $nova.formatDate(v.duedate) }}</span>
                    <v-spacer></v-spacer>
                    <v-chip label class="caption ml-1 px-2" :color="Math.floor(
                      (new Date(v.duedate) - new Date()) /
                      (60 * 60 * 24 * 1000)
                    ) < 0
                        ? 'error'
                        : 'info'
                      " small>{{
                        Math.floor(
                          (new Date(v.duedate) - new Date()) /
                          (60 * 60 * 24 * 1000)
                        )
                      }}
                      Days</v-chip>
                  </div>
                </v-card-text>
                <v-card-text class="px-0 pt-0 pb-3">
                  <div class="py-1 px-4 grey lighten-3 d-flex align-center">
                    <span>Total Requests</span>
                    <v-spacer></v-spacer>
                    <span class="title pr-2">{{
                      ((v.summary || {}).requested || 0) +
                      ((v.summary || {}).approved || 0) +
                      ((v.summary || {}).cancelled || 0) +
                      ((v.summary || {}).pending || 0)
                      }}</span>
                  </div>
                </v-card-text>
                <v-card-text class="pt-0">
                  <div v-if="
                    ((v.summary || {}).requested || 0) +
                    ((v.summary || {}).pending || 0) +
                    ((v.summary || {}).approved || 0) +
                    ((v.summary || {}).cancelled || 0) >
                    0
                  ">
                    <canvas :ref="v._id" style="max-height: 120px"></canvas>
                  </div>
                  <div v-else class="py-5 text-center">
                    <div class="grey--text text--lighten-1 title pb-2">
                      No Request added
                    </div>
                    <v-btn color="primary" small @click="
                      $nova.gotoLink({
                        path: '/pbc/project/view/' + v._id,
                        hash: 'add',
                      })
                      "><v-icon left>mdi-plus</v-icon>Add Request</v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </div>

      <div v-else class="mb-10">
        <v-alert class="shadow-on" border="left" :value="true">
          <span class="subtitle-1">No Status & tasks that needs you attention</span>
        </v-alert>
      </div>
      <div class="title mb-6" v-if="subscriptions.length > 0">
        <v-icon>mdi-chevron-triple-right</v-icon>
        <span class="ml-4">Your Active Subscriptions</span>
      </div>

      <div v-if="subscriptions.length > 0" class="mb-10">
        <v-row>
          <v-col cols="12" md="4" v-for="(v, k) in subscriptions" :key="k">
            <v-card class="radius-all "
            style="border: 1px solid #ddd;"
            @click="
              $nova.gotoLink({
                path: v.status
                  ? (v.product_detail || {}).homepage || ''
                  : '#',
              })
              ">
              <v-card-text class="pa-0 d-flex align-center">
                <div :class="`white--text ${colors[(v.product_detail || {}).solution || 'x'] || ''
                  } pa-4 display-1`">
                  <v-icon color="white">mdi-format-list-group</v-icon>
                </div>
                <div class="px-4">
                  <div class="subtitle-1 primary--text font-weight-bold">
                    {{ (v.product_detail || {}).name || "" }}
                  </div>
                  <div v-if="v.status" class="success--text font-weight-bold">
                    Active
                    <v-icon color="success">mdi-check-decagram</v-icon>
                  </div>
                  <div v-else class="error--text font-weight-bold">
                    Inactive
                    <v-icon color="error">mdi-alert</v-icon>
                  </div>
                </div>

              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-if="subscriptions.length === 0" class="my-10">
        <v-alert border="left" class="subtitle-1 shadow-on" type="info" :value="true">
          You have not subscribed to any CART offerings. Please subscribe and
          start using it.
        </v-alert>
      </div>

      <div class="title my-6">
        <v-icon>mdi-chevron-triple-right</v-icon>
        <span class="ml-4">Inactive Subscriptions</span>
      </div>
      <div class="mb-10">
        <v-row>
          <v-col cols="12" md="3"  >

            <v-card class="all-height" style="border: 1px solid #ddd;">
              <v-card-text class="ccompliance title text-center">
                <v-avatar size="45" class="white ccompliance--text"><strong>C</strong></v-avatar>
                <div class="white--text">Compliance</div>
                <div class="subtitle-1 mx-4 white--text font-weight-light">
                  Convenience Over Compliance
                </div>
              </v-card-text>
              <v-card-text class="text-center pa-0">
                <div class="hoverable cursor-pointer px-4 py-2" v-ripple>
                  E-Invoicing
                </div>
                <div class="hoverable cursor-pointer px-4 py-2" v-ripple>
                  Reconciliation
                </div>
                <div class="hoverable cursor-pointer px-4 py-2" v-ripple>
                  Direct Tax
                </div>
                <div class="hoverable cursor-pointer px-4 py-2" v-ripple>
                  Indirect Tax
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="3" >
            <v-card class="all-height" style="border: 1px solid #ddd;">
              <v-card-text class="caccounting title text-center">
                <v-avatar size="45" class="white caccounting--text"><strong>A</strong></v-avatar>
                <div class="white--text">Accounting</div>
                <div class="subtitle-1 white--text font-weight-light">
                  Reliable & Intelligent Reporting
                </div>
              </v-card-text>
              <v-card-text class="text-center pa-0">
                <div class="hoverable cursor-pointer px-4 py-2" v-ripple>
                  Financial Close
                </div>
                <div class="hoverable cursor-pointer px-4 py-2" v-ripple>
                  Accounting Reconciliations
                </div>
                <div class="hoverable cursor-pointer px-4 py-2" v-ripple>
                  GAAP Compliance
                </div>
              </v-card-text>
            </v-card>

          </v-col>
          <v-col cols="12" md="3" >
            <v-card class="all-height" style="border: 1px solid #ddd;">
              <v-card-text class="crisk title text-center">
                <v-avatar size="45" class="white crisk--text"><strong>R</strong></v-avatar>
                <div class="white--text">Risk</div>
                <div class="subtitle-1 white--text font-weight-light">
                  A Virtual Controls Management Framework
                </div>
              </v-card-text>
              <v-card-text class="text-center pa-0">
                <div class="hoverable cursor-pointer px-4 py-2" v-ripple>
                  Risk Assurance Management
                </div>
                <div class="hoverable cursor-pointer px-4 py-2" v-ripple>
                  Process Assurance Management
                </div>
                <div class="hoverable cursor-pointer px-4 py-2" v-ripple>
                  Conserve – Controllership Bots
                </div>
                <div class="hoverable cursor-pointer px-4 py-2" v-ripple>
                  Conserve – Information Workflow
                </div>
              </v-card-text>
            </v-card>

          </v-col>
          <v-col cols="12" md="3" >
            <v-card class="all-height" style="border: 1px solid #ddd;">
              <v-card-text class="ctransaction title text-center">
                <v-avatar size="45" class="white ctransaction--text"><strong>T</strong></v-avatar>
                <div class="white--text">Transactions</div>
                <div class="subtitle-1 white--text font-weight-light">
                  Augmenting Transactions
                </div>
              </v-card-text>
              <v-card-text class="text-center pa-0">
                <div class="hoverable cursor-pointer px-4 py-2" v-ripple>
                  Valuations
                </div>
                <div class="hoverable cursor-pointer px-4 py-2" v-ripple>
                  Due Diligences, Transaction Support & Earnouts
                </div>
                <div class="hoverable cursor-pointer px-4 py-2" v-ripple>
                  Deal Advisory - Fund Raise/M&A
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Chart,
  PolarAreaController,
  RadialLinearScale,
  ArcElement,
  Legend,
  Title,
  Tooltip,
  DoughnutController,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.register(
  PolarAreaController,
  RadialLinearScale,
  ArcElement,
  Legend,
  Title,
  Tooltip,
  DoughnutController,
  ChartDataLabels
);

export default {
  name: "ucpdashboard",
  // props: ['props'],
  data: function () {
    return {
      pageprops: {
        name: "Client",
        breadcrumbs: [],
      },
      colors: {
        Compliance: "ccompliance",
        Risk: "crisk",
        Accounting: "caccounting",
        Transaction: "ctransaction",
        Other: "primary",
        All: "primary",
      },
      subscriptions: [],
      projects: [],
      projectsloading: 0,
      // hasactive: false,
      tasklist: {},
      loading: false,
      addflag: false,
      editflag: false,
      selectedindex: 0,
      modifydata: {},
      errors: {},
      charts: [],
      chartdata: {},
      selectdata: {
        type: [
          { name: "Individual", value: "Individual" },
          { name: "Company", value: "Company" },
          { name: "Firm", value: "Firm" },
          { name: "Trust", value: "Trust" },
          { name: "Government", value: "Government" },
          { name: "Others", value: "Others" },
        ],
      },
    };
  },
  // components: {
  //   // pageHeader,managelist
  // },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },

  methods: {
    refreshData() {
      this.errors = {};
      this.addflag = false;
      this.editflag = false;
      this.subscriptions = [];
      this.hasactive = false;
      this.getData();
    },
    getData() {
      this.loading = true;
      // this.$store.commit("addLoading");
      // this.$store.commit("progressSetLoad", true);
      this.axios
        .post("/v2/ucp/dashboard")
        .then((dt) => {
          if (dt.data.status === "success") {
            this.subscriptions = dt.data.data;
            this.projects = [];

            if (this.subscriptions.length > 0) {

              for (const i of this.subscriptions) {
                this.loadProduct((i.product_detail || {}).code || "");
              }
            }
          } else {
            console.log("Error fetching data");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
          // this.$store.commit("removeLoading");
          // this.$store.commit("progressSetLoad", false);
        });
    },
    loadProduct(t = "") {
      let url = "";
      if (t === "pbc") url = "/v2/pbc/project/getactive";
      if (url) {
        this.projectsloading++;
        this.axios
          .post(url)
          .then((dt) => {
            if (dt.data.status === "success") {

              for (const i of dt.data.data) {
                i.__dname = t;
                this.projects.push(i);
              }
              this.setChart();
            } else {
              console.log("Error fetching " + t + " list");
            }
          })
          .catch((err) => {
            this.$store.commit(
              "sbError",
              err.message || err || "Unknown error!"
            );
            console.log(err);
          })
          .finally(() => {
            this.projectsloading--;
          });
      }
    },
    getPBCChartData: function () {
      return {
        type: "doughnut",
        data: {
          labels: [
            "Yet to Respond",
            "Waiting for Approval",
            "Closed",
            "Cancelled",
          ],
          datasets: [
            {
              label: "Count",
              data: [],
              backgroundColor: [],
              // datalabels: {
              //   anchor: 'center',
              //   backgroundColor: null,
              //   borderWidth: 0
              // },
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "right",
            },
            datalabels: {
              color: "white",
              display: function (context) {
                let dataset = context.dataset;
                let count = dataset.data.length;
                let value = dataset.data[context.dataIndex];
                return (value / count || 0) > 0.1;
              },
              font: {
                weight: "bold",
              },
            },
          },
        },
      };
    },
    setChart: function () {
      this.$nextTick(function () {
        for (let i = 0; i < this.projects.length; i++) {
          const el = { ...this.projects[i] };
          if (el.__dname === "pbc") {
            if (
              ((el.summary || {}).requested || 0) +
              ((el.summary || {}).pending || 0) +
              ((el.summary || {}).approved || 0) +
              ((el.summary || {}).cancelled || 0) >
              0
            ) {
              let canvasid = el._id;

              if (this.charts[canvasid]) this.charts[canvasid].destroy();
              this.chartdata[canvasid] = this.getPBCChartData();
              this.chartdata[canvasid].data.datasets = [
                {
                  label: "Count",
                  data: [
                    (el.summary || {}).requested || 0,
                    (el.summary || {}).pending || 0,
                    (el.summary || {}).approved || 0,
                    (el.summary || {}).cancelled || 0,
                  ],
                  backgroundColor: this.$nova.getColors(4, 0.5),
                  // datalabels: this.chartdata[canvasid].data.datasets[0].datalabels
                },
              ];
              this.charts[canvasid] = new Chart(
                this.$refs[canvasid][0].getContext("2d"),
                this.chartdata[canvasid]
              );
            }
          }
        }
      });
    },
    // save() {
    //   this.axios
    //     .post("/v2/client/add", { data: [this.modifydata] })
    //     .then((dt) => {
    //       if (dt.data.status === "success") {
    //         this.refreshData();
    //         this.$store.commit("refreshClient", true);
    //       } else {
    //         this.errors = {};
    //         if (dt.data.data.length > 0) {
    //           if (dt.data.data[0].index0) this.errors = dt.data.data[0].index0;
    //         }
    //         console.log("Error adding client");
    //       }
    //     });
    // },
    // update() {
    //   if (this.modifydata._id) {
    //     this.axios
    //       .post("/v2/client/edit", {
    //         data: [this.modifydata],
    //         id: this.modifydata._id,
    //       })
    //       .then((dt) => {
    //         if (dt.data.status === "success") {
    //           this.refreshData();
    //           this.$store.commit("refreshClient", true);
    //         } else {
    //           this.errors = {};
    //           if (dt.data.data.length > 0) {
    //             if (dt.data.data[0].index0)
    //               this.errors = dt.data.data[0].index0;
    //           }
    //           console.log("Error updating client");
    //         }
    //       });
    //   } else console.log("No ID found to update");
    // },
  },
  computed: {},
  watch: {
    "$store.state.client": function () {
      this.refreshData();
    },
  },
};
</script>
